import { Chart } from "react-google-charts";
import { useContext, useState, useEffect } from 'react';
import AuthContext from "../../contexts/auth-context";
import AppContext from "../../contexts/app-context";
import useAxios from "../../hooks/useAxios";
import { validateStateChart } from "../../Utils/validateHttpResponse";
import { urls, toastify_status } from '../config/variables';
import Toastify from "../ui/Toastify";
import CircularProgress from '@mui/material/CircularProgress';
import ChartsStyle from './Charts.Module.js'
import {useTranslation} from 'react-i18next'

const StatePieChart = () => {
    const classes = ChartsStyle()
    const [analysisStateData, setAnalysisStateData] = useState('');
    const appCtx = useContext(AppContext)
    const searchByDateQuery = appCtx.searchByDateQuery
    const reFetchChartsData = appCtx.reFetchChartsData
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const {t} = useTranslation('analytics')

    useEffect(() => {
      getAnalysisStateData()
      // eslint-disable-next-line
  }, [searchByDateQuery, reFetchChartsData])

  const getAnalysisStateData = () => {
      operation(
          {
            method: 'GET',
            url: urls.ANALYSIS_ENCOUNTERS + searchByDateQuery,
            headers: { Authorization: `token ${token}` },
            data: {},
          },
          handleGetAnalysisStateData,
          (err) => console.log(err)
      );
  }

  const getColor = (state) => {
    switch (state) {
        case 'Approved':
            return '#4CAF50';
        case 'Partially accepted':
            return '#FFC107';
        case 'Rejected':
            return '#F44336';
        case 'Pending approval':
            return '#747264';
        case 'Canceled':
            return '#0C0C0C';
        default:
            return '#FFFFFF';
    }
  };

  const handleGetAnalysisStateData = (response) => {
    if(validateStateChart(response)) {
      setAnalysisStateData(response)
      // state map takes the data from the backend [{"state":"Approved","state_count":1},{"state":"Partially accepted","state_count":7}]
      // then turned it into an object {"Approved":1,"Partially accepted":7}
      const arrayData = response.map((object) => {
          return [t(object.state), object.state_count, getColor(object.state)]
        })
      const transformedData = [["Element", "Density", { role: "style" }], ...arrayData]

      setAnalysisStateData(transformedData)
    } else {
      Toastify({message: 'invalid reloaded encounter response', state: toastify_status.ERROR})
    }
  }

  return analysisStateData.length > 0 ? (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="100%"
      data={analysisStateData || []}
    />
  ) : (
    <div
      style={classes.state_bar_chart_progress_box}
    >
      <CircularProgress />
    </div>
  );
}

export default StatePieChart