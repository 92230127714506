import { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import useAxios from '../../hooks/useAxios';
import { urls, app_paths, http_response_messages, toastify_status, INITIAL_PATIENT_INSURANCE, patientFormType } from '../config/variables';
import classes from './NewUserForm.module.css';
import { Navigate, useParams } from 'react-router-dom';
import NewPatientSchema from '../schema/NewPatient.schema';
import {useTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress';
import Toastify from '../ui/Toastify';
import { validateUserResponse, validateInsuranceAndFacilitiesList } from '../../Utils/validateHttpResponse';
import SearchableSelect from '../ui/SearchableSelect/SearchableSelect';
import { nationalities } from '../../Utils/nationalaties';

function NewUserForm({type}) {
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const token = authCtx.token;
  const { operation } = useAxios();
  const navigate = useNavigate();
  const {t} = useTranslation('newPatient')
  //const direction = appCtx.direction

  const [userResponse, setUserResponse] = useState(undefined);
  const [userError, setUserError] = useState(undefined);
  const [validationErrors, setValidationErrors] = useState({});
  const [insuranceListResponse, setInsuranceListResponse] = useState([]);
  const [insuranceListError, setInsuranceListError] = useState([]);
  const [loadingAddPatient, setLoadingAddPatient] = useState(false);

  // patient id ---> used to update a patient by doctor and insurance
  const { patientID } = useParams();
  // fetched patient information ---> used to update the patient by doctor and insurance
  const [patientInformation, setPatientInformation] = useState('')
  const [loadingPatientInformation, setLoadingPatientInformation] = useState(true)

  // get current date function
  const todayDate = () => {
  let today = new Date()
  let year = today.getFullYear() + 1;
  let month = String(today.getMonth() + 1).padStart(2, '0')
  let day = String(today.getDate()).padStart(2, '0')
  let currentDate = year + '-' + month + '-' + day

  return currentDate
  }

  const [enteredName, setEnteredName] = useState('');
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [enteredPhone, setEnteredPhone] = useState('');
  const [gender, setGender] = useState('')
  const [enteredBirthday, setEnteredBirthday] = useState('')

  const [nationality, setNationality] = useState('')
  const enterednationality = nationality.nationality;

  const [enteredEid, setEnteredEid] = useState('')
  const [enteredphysical_address, setEnteredphysical_address] = useState('')
  const [enteredemployer, setEnteredemployer] = useState('')

  const [primaryInsurance, setPrimaryInsurance] = useState(INITIAL_PATIENT_INSURANCE)
  const [secondaryInsurance, setSecondaryInsurance] = useState(INITIAL_PATIENT_INSURANCE)
  const enteredPrimaryInsurance = primaryInsurance?.id;
  const enteredSecondaryInsurance = secondaryInsurance?.id;

  const [enteredPatientPrimaryInsID, setEnteredPatientPrimaryInsID] = useState('')
  const [enteredPatientPrimaryInsExp, setEnteredPatientPrimaryInsExp] = useState('')

  const [enteredPatientSecondaryInsID, setEnteredPatientSecondaryInsID] = useState('')
  const [enteredPatientSecondaryInsExp, setEnteredPatientSecondaryInsExp] = useState('')

  // if form type is create then validate email and passeord
  const checkEmailAndPassword = () => {
    return !enteredEmail ||
    !enteredPassword
  }

  const isDisabled = //disabled submit button
    (type === patientFormType.create &&
    checkEmailAndPassword()) ||
    !enteredName ||
    !enteredBirthday ||
    !gender.gender ||
    Object.keys(validationErrors).length > 0;

    const disabledPrimaryInsurance = () => {
      if(enteredPrimaryInsurance === 1) {
        return false
      } else if (enteredPrimaryInsurance !== 1 && enteredPatientPrimaryInsID && enteredPatientPrimaryInsExp) {
        return false
      } else {
        return true
      }
    }

    const disabledSecondaryInsurance = () => {
      if(enteredSecondaryInsurance === 1) {
        return false
      } else if (enteredSecondaryInsurance !== 1 && enteredPatientSecondaryInsID && enteredPatientSecondaryInsExp) {
        return false
      } else {
        return true
      }
    }

  const validateField = (fieldId, value, otherValue) => {
    const { error } = NewPatientSchema.validate({ [fieldId]: value }, { abortEarly: false, context: {otherValue} });
    const errors = {};
    if (error) {
      for (const detail of error.details) {
        errors[detail.context.key] = detail.message;
      }
    }
    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors, [fieldId]: errors };
      if (Object.keys(updatedErrors[fieldId]).length === 0) {
        delete updatedErrors[fieldId]; // Remove the fieldId if there are no errors
      }
      return updatedErrors;
    });
  };

  /* If user created successfully, add the user to the app context */
  useEffect(() => {
    if (!!userResponse) {
      appCtx.addSelectedUser(userResponse);
      appCtx.handleReFetchUsersList(type)
    }
    // eslint-disable-next-line
  }, [userResponse, userError, appCtx]);

  /* Get the list of insurance in the system */
  useEffect(() => {
    operation(
      {
        method: 'GET',
        url: urls.LIST_INSURANCES,
        headers: { Authorization: `token ${token}` },
        data: {},
      },
      handleSetInsuranceListResponse,
      setInsuranceListError
    );
    // need operation and token
    // eslint-disable-next-line
  }, []);

  const handleSetInsuranceListResponse = (insuranceList) => {
    if(validateInsuranceAndFacilitiesList(insuranceList)) {
      setInsuranceListResponse(insuranceList)
    } else {
      console.log('invalid insurance list response')
      Toastify({message: 'invalid insurance list response', state: toastify_status.ERROR})
    }
  }

  /* InsuranceListError should be handled properly later  */
  useEffect(() => {
    if (typeof insuranceListError !== 'undefined') {
      console.log (insuranceListError)
    }
  }, [insuranceListError]);
  

  const submitHandler = (event) => {
    setLoadingAddPatient(true)
    event.preventDefault();
    const hasErrors = Object.values(validationErrors).some((fieldErrors) => Object.keys(fieldErrors).length > 0);
    if (hasErrors) {
      console.log(hasErrors);
      return;
    }
    const user = {
      name: enteredName,
      birthdate: enteredBirthday,
      gender: gender.gender,
      phone_number: enteredPhone,
      eid: enteredEid,
      physical_address: enteredphysical_address,
      employer: enteredemployer,
      nationality: enterednationality,
      ...(type === patientFormType.create && {
        email: enteredEmail,
        password: enteredPassword,
      })
    };
    operation(
      {
        method: type === patientFormType.create ? 'POST' : 'PATCH',
        url: type === patientFormType.create ? urls.CREATE_A_PATIENT : urls.GET_A_PATIENT + patientID,
        headers: { Authorization: `token ${token}` },
        data: {
          user: user,
          primary_insurance:enteredPrimaryInsurance.toString(),
          patient_primary_insurance_id: enteredPatientPrimaryInsID,
          secondary_insurance:enteredSecondaryInsurance.toString(),
          patient_secondary_insurance_id: enteredPatientSecondaryInsID,
          patient_primary_insurance_expiration: enteredPatientPrimaryInsExp,
          patient_secondary_insurance_expiration: enteredPatientSecondaryInsExp,
          extrainfo: ""
        },
      },
      handleUserResponse,
      setUserError
    );
  };

  const handleUserResponse = (userResponst) => {
    setLoadingAddPatient(false)

    if(validateUserResponse(userResponst)) {
      setUserResponse(userResponst)
      setUserError(undefined)
      showSuccessMessage()
    } else {
      Toastify({message: 'invalid user response', state: toastify_status.ERROR})
      console.log('invalid user response')
    }
  }

  const showSuccessMessage = () => {
    if(type === patientFormType.create) {
      Toastify({message: http_response_messages.CREATE_PATIENT, state: toastify_status.SUCCESS})
    } else {
      Toastify({message: http_response_messages.UPDATE_PATIENT, state: toastify_status.SUCCESS})
    }
  }

    /* If user is not created successfully, show the error message */
  useEffect(() => {
    if (userError) {
      if (userError.response.data) {
        const errors = userError.response.data.errors
        errors.map((error) => (
          Toastify({ message: error.detail, state: toastify_status.ERROR })
        ))
      } else {
        Toastify({ message: http_response_messages.ERROR, state: toastify_status.ERROR })
      }
    }
    setLoadingAddPatient(false)
  }, [userError]);

  const handleFormReset = (event) => {
    event.preventDefault();

    setValidationErrors({});
    //To be cleared when defaultValues are removed
    setEnteredName('')
    setEnteredEmail('')
    setEnteredPhone('')
    setGender('')
    setEnteredBirthday('')
    setNationality('')
    setEnteredEid('')
    setEnteredphysical_address('')
    setEnteredPatientPrimaryInsID('')
    setEnteredPatientPrimaryInsExp('')
    setEnteredPatientSecondaryInsID('')
    setEnteredPatientSecondaryInsExp('')
    setEnteredemployer('')
    setPrimaryInsurance(INITIAL_PATIENT_INSURANCE);
    setSecondaryInsurance(INITIAL_PATIENT_INSURANCE)
  };

const goSomeWhere = (userResponse) => {
  if (appCtx.userRole === "doctor" ) {
    if(type === patientFormType.create) {
      console.log("Role: Doctor, navigate to new encounter")
      // navigate("/");
      //navigate(0);
      return  <Navigate to={app_paths.NEW_ENCOUNTER.replace(':patientID', userResponse.id)} replace={true} />
    } else {
      return <Navigate to={app_paths.PATIENT_PROFILE.replace(':patientID', userResponse.id)} replace={true} />
    }
  }
  else if (appCtx.userRole === "insurance_admin" ) {
    if(type === patientFormType.create) {
      console.log("Role: Insurance, navigate to home page")
      navigate("/");
      navigate(0);
      return
    } else {
      <Navigate to={app_paths.PATIENT_PROFILE.replace(':patientID', userResponse.id)} replace={true} />
    }
  }
}

// remove errors and set expire to current date in case of out of pocket primary insuranse

useEffect(() => {
  if (enteredPrimaryInsurance === 1) {
    setValidationErrors(prevErrors => {
      const { patient_primary_insurance_id, patient_primary_insurance_expiration, ...rest } = prevErrors;
      return rest;
    });
    setEnteredPatientPrimaryInsID('')
    setEnteredPatientPrimaryInsExp(todayDate())
  } else {
    // patient_primary_insurance_expirationInputRef.current.value = '' <--- i need this code commented out to change it later
  }
}, [enteredPrimaryInsurance]);

// remove errors and set expire to current date in case of out of pocket secondary insuranse

useEffect(() => {
  if (enteredSecondaryInsurance === 1) {
    setValidationErrors(prevErrors => {
      const { patient_secondary_insurance_id, patient_secondary_insurance_expiration, ...rest } = prevErrors;
      return rest;
    });
    setEnteredPatientSecondaryInsID('')
    setEnteredPatientSecondaryInsExp(todayDate())
  } else {
    // patient_secondary_insurance_expirationInputRef.current.value = '' <--- i need this code commented out to change it later
  }
}, [enteredSecondaryInsurance])

const handleChangeNationality = (value) => {
  validateField('nationality', value.nationality)
  setNationality(value)
}

const handleChangeGender = (value) => {
  validateField('gender', value.gender)
  setGender(value)
}

const handleChangePrimaryInsurance = (value) => {
  setPrimaryInsurance(value)
}

const handleChangeSecondaryInsurance = (value) => {
  setSecondaryInsurance(value)
}

useEffect(() => {
  validateField('primary_insurance', primaryInsurance?.id, secondaryInsurance?.id)
  validateField('secondary_insurance', secondaryInsurance?.id, primaryInsurance?.id)
}, [primaryInsurance?.id, secondaryInsurance?.id])

// get the patient information to update it by doctor and insurance
const getPatientFromPatientID = () => {
  operation(
    {
      method: 'GET',
      url: urls.GET_A_PATIENT + patientID,
      headers: { Authorization: `token ${token}` },
      data: {},
    },
    handleSetPatientInformation,
    handleErrorPatientInformation
  );
}

const handleSetPatientInformation = (patient) => {
  setLoadingPatientInformation(false)
  if(validateUserResponse(patient)) {
    setPatientInformation(patient)
  } else {
    Toastify({message: 'invalid user response', state: toastify_status.ERROR})
  }
}

const handleErrorPatientInformation = (error) => {
  setLoadingPatientInformation(false)
  Toastify({message: 'Error fetching patient information', state: toastify_status.ERROR})
}

useEffect(() => {
  if(patientID) {
    getPatientFromPatientID()
  }
  // eslint-disable-next-line
}, [patientID])

useEffect(() => {
  if (
    type === patientFormType.update &&
    patientID &&
    patientInformation
  ) {
    const {
      primary_insurance,
      patient_primary_insurance_id,
      patient_primary_insurance_expiration,
      secondary_insurance,
      patient_secondary_insurance_id,
      patient_secondary_insurance_expiration,
    } = patientInformation;

    const {
      name,
      email,
      phone_number,
      gender,
      birthdate,
      nationality,
      eid,
      physical_address,
      employer,
    } = patientInformation.user;

    setEnteredName(name)
    setEnteredEmail(email)
    setEnteredPhone(phone_number)
    setGender(gender === 'M' ? { name: "Male", gender: "M" } : { name: "Female", gender: "F" })
    setEnteredBirthday(birthdate)
    setNationality(nationality ? { nationality: nationality } : '')
    setEnteredEid(eid)
    setEnteredphysical_address(physical_address)
    setPrimaryInsurance(insuranceListResponse.find((insurance) => insurance.id === primary_insurance))
    setEnteredPatientPrimaryInsID(patient_primary_insurance_id)
    setEnteredPatientPrimaryInsExp(patient_primary_insurance_expiration)
    setSecondaryInsurance(insuranceListResponse.find((insurance) => insurance.id === secondary_insurance))
    setEnteredPatientSecondaryInsID(patient_secondary_insurance_id)
    setEnteredPatientSecondaryInsExp(patient_secondary_insurance_expiration)
    setEnteredemployer(employer)
  }
  // eslint-disable-next-line
}, [type, patientID, patientInformation]);

const handleChangeField = (event) => {
  const {id, value} = event.target
  validateField(id, value)
  switch (id) {
    case 'email':
      setEnteredEmail(value);
      break;
    case 'name':
      setEnteredName(value);
      break;
    case "password":
      setEnteredPassword(value);
      break;
    case 'phone_number':
      setEnteredPhone(value);
      break;
    case 'birthdate':
      setEnteredBirthday(value);
      break;
    case 'eid':
      setEnteredEid(value);
      break;
    case 'physical_address':
      setEnteredphysical_address(value);
      break;
    case 'employer':
    setEnteredemployer(value);
      break;
    case 'patient_primary_insurance_id':
      setEnteredPatientPrimaryInsID(value);
      break;
    case 'patient_primary_insurance_expiration':
      setEnteredPatientPrimaryInsExp(value);
      break;
    case 'patient_secondary_insurance_id':
      setEnteredPatientSecondaryInsID(value);
      break;
    case 'patient_secondary_insurance_expiration':
      setEnteredPatientSecondaryInsExp(value);
      break;
    default:
      return null;
  }
}

  return (
    <div className={classes.container}>
      <form onReset={handleFormReset} onSubmit={submitHandler}>
        <div>
          <h1 className={classes.title}>{t('PatientInformation')}</h1>
          <div className={classes.form_container}>
            <div className={classes.control}>
              <label htmlFor="name">{t('Name')} <span className={classes.required}>*</span></label>
              <input
                type="text"
                id="name"
                placeholder="John Doe"
                value={enteredName}
                onChange={handleChangeField}
              />
              {validationErrors.name && <p className={classes.helperText}>{validationErrors.name.name}</p>}
            </div>

            <div className={classes.control}>
              <label htmlFor="email">{t('UserEmail')} <span className={classes.required}>*</span></label>
              <input
                type="email"
                id="email"
                placeholder="example@example.com"
                value={enteredEmail}
                onChange={handleChangeField}
                disabled={type === patientFormType.update}
              />
              {validationErrors.email && <p className={classes.helperText}>{validationErrors.email.email}</p>}
            </div>

            {type === patientFormType.create && <div className={classes.control}>
              <label htmlFor="password">{t('UserPassword')} <span className={classes.required}>*</span></label>
              <input
                type="password"
                id="password"
                placeholder="********"
                value={enteredPassword}
                onChange={handleChangeField}
              />
              {validationErrors.password && <p className={classes.helperText}>{validationErrors.password.password}</p>}
            </div>}

            <div className={classes.searchable}>
              <label htmlFor="gender">{t('Gender')} <span className={classes.required}>*</span></label>
              <SearchableSelect
                  value={gender}
                  onChange={handleChangeGender}
                  options={[{name: t('Male'), gender: 'M'}, {name: t('Female'), gender: 'F'}]}
                  placeholder={'Select Gender'}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.gender}
                  searchable={false}
                />
                {validationErrors.gender && <p className={classes.helperText}>{validationErrors.gender.gender}</p>}
            </div>

            <div className={classes.control}>
              <label htmlFor="birthdate">{t('Birthdate')} <span className={classes.required}>*</span></label>
              <input
                type="date"
                id="birthdate"
                placeholder="YYYY-MM-DD (e.g., 1990-01-01)"
                value={enteredBirthday}
                onChange={handleChangeField}
              />
              {validationErrors.birthdate && <p className={classes.helperText}>{validationErrors.birthdate.birthdate}</p>}
            </div>

            <div className={classes.control}>
              <label htmlFor="phone_number">{t('Phone')}</label>
              <input
                type="text"
                id="phone_number"
                placeholder="+1234567890"
                value={enteredPhone}
                onChange={handleChangeField}
              />
              {validationErrors.phone_number && <p className={classes.helperText}>{validationErrors.phone_number.phone_number}</p>}
            </div>

            <div className={classes.searchable}>
              <label htmlFor="nationality">{t('Nationality')}</label>
              <SearchableSelect
                  value={nationality}
                  onChange={handleChangeNationality}
                  options={nationalities}
                  placeholder={'Select nationality'}
                  getOptionLabel={(option) => option.nationality}
                  getOptionValue={(option) => option.nationality}
                />
                {validationErrors.nationality && <p className={classes.helperText}>{validationErrors.nationality.nationality}</p>}
            </div>

            <div className={classes.control}>
              <label htmlFor="eid">{t('GovernmentID')}</label>
              <input
                type="text"
                id="eid"
                placeholder="A12345678"
                value={enteredEid}
                onChange={handleChangeField}
              />
              {validationErrors.eid && <p className={classes.helperText}>{validationErrors.eid.eid}</p>}
            </div>

            <div className={classes.control}>
              <label htmlFor="physical_address">{t('PhysicalAddress')}</label>
              <input
                type="text"
                id="physical_address"
                placeholder="123 Main Street, City, State"
                value={enteredphysical_address}
                onChange={handleChangeField}
              />
              {validationErrors.physical_address && (
                <p className={classes.helperText}>{validationErrors.physical_address.physical_address}</p>
              )}
            </div>

            <div className={classes.control}>
              <label htmlFor="employer">{t('Employer')}</label>
              <input
                type="text"
                id="employer"
                placeholder="ABC Company"
                value={enteredemployer}
                onChange={handleChangeField}
              />
              {validationErrors.employer && <p className={classes.helperText}>{validationErrors.employer.employer}</p>}
            </div>
          </div>

          <div className={classes.space} />

          <h1 className={classes.title}>{t('PatientPrimaryInsurance')}</h1>
          <div className={classes.form_container}>
            <div className={classes.searchable}>
              <label htmlFor="primary_insurance">{t('PrimaryInsurance')} <span className={classes.required}>*</span></label>
              <SearchableSelect
                  value={primaryInsurance}
                  onChange={handleChangePrimaryInsurance}
                  options={insuranceListResponse}
                  placeholder={'Select primary insurance'}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  searchable={false}
                />
              {validationErrors.primary_insurance &&
               <p className={classes.helperText}>{validationErrors.primary_insurance.primary_insurance}</p>}
            </div>

            <div className={classes.control}>
              <label htmlFor="patient_primary_insurance_id" className={enteredPrimaryInsurance === 1 ? classes.hide : ''}>
                {t('PrimaryInsuranceID')} <span className={classes.required}>*</span>
              </label>
              <input
                className={enteredPrimaryInsurance === 1 ? classes.hide : ''}
                type="text"
                id="patient_primary_insurance_id"
                placeholder={enteredPrimaryInsurance === '1' ? 'No Need For ID (Out of Pocket)' : 'primary insrance id'}
                value={enteredPatientPrimaryInsID}
                disabled={enteredPrimaryInsurance === 1}
                onChange={handleChangeField}
              />
              {validationErrors.patient_primary_insurance_id &&
               <p className={classes.helperText}>{validationErrors.patient_primary_insurance_id.patient_primary_insurance_id}</p>
              }
            </div>

            <div className={classes.control}>
              <label htmlFor="patient_primary_insurance_expiration" className={enteredPrimaryInsurance === 1 ? classes.hide : ''}>
                {t('PrimaryInsuranceExpiration')} <span className={classes.required}>*</span>
              </label>
              <input
                className={enteredPrimaryInsurance === 1 ? classes.hide : ''}
                type="date"
                id="patient_primary_insurance_expiration"
                placeholder="YYYY-MM-DD (e.g., 2024-01-01)"
                value={enteredPatientPrimaryInsExp}
                disabled={enteredPrimaryInsurance === 1}
                onChange={handleChangeField}
              />
              {validationErrors.patient_primary_insurance_expiration &&
               <p className={classes.helperText}>{validationErrors.patient_primary_insurance_expiration.patient_primary_insurance_expiration}</p>
              }
            </div>
          </div>

          <div className={classes.space} />

          <h1 className={classes.title}>{t('PatientSecondaryInsurance')}</h1>
          <div className={classes.form_container}>
            <div className={classes.searchable}>
              <label htmlFor="secondary_insurance">{t('SecondaryInsurance')} <span className={classes.required}>*</span></label>
              <SearchableSelect
                  value={secondaryInsurance}
                  onChange={handleChangeSecondaryInsurance}
                  options={insuranceListResponse}
                  placeholder={'Select secondary insurance'}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  searchable={false}
                />
              {validationErrors.secondary_insurance &&
               <p className={classes.helperText}>{validationErrors.secondary_insurance.secondary_insurance}</p>
              }
            </div>

            <div className={classes.control}>
              <label htmlFor="patient_secondary_insurance_id" className={enteredSecondaryInsurance === 1 ? classes.hide : ''}>
                {t('SecondaryInsuranceID')} <span className={classes.required}>*</span>
              </label>
              <input
                className={enteredSecondaryInsurance === 1 ? classes.hide : ''}
                type="text"
                id="patient_secondary_insurance_id"
                placeholder={enteredSecondaryInsurance === '1' ? 'No Need For ID (Out of Pocket)' : 'secondary insrance id'}
                value={enteredPatientSecondaryInsID}
                disabled={enteredSecondaryInsurance === 1}
                onChange={handleChangeField}
              />
              {validationErrors.patient_secondary_insurance_id &&
               <p className={classes.helperText}>{validationErrors.patient_secondary_insurance_id.patient_secondary_insurance_id}</p>
              }
            </div>

            <div className={classes.control}>
              <label htmlFor="patient_secondary_insurance_expiration" className={enteredSecondaryInsurance === 1 ? classes.hide : ''}>
                {t('SecondaryInsuranceExpiration')} <span className={classes.required}>*</span>
              </label>
              <input
                className={enteredSecondaryInsurance === 1 ? classes.hide : ''}
                type="date"
                id="patient_secondary_insurance_expiration"
                placeholder="YYYY-MM-DD (e.g., 2024-01-01)"
                value={enteredPatientSecondaryInsExp}
                disabled={enteredSecondaryInsurance === 1}
                onChange={handleChangeField}
              />
              {validationErrors.patient_secondary_insurance_expiration &&
               <p className={classes.helperText}>{validationErrors.patient_secondary_insurance_expiration.patient_secondary_insurance_expiration}</p>
              }
            </div>
          </div>
        </div>

        <div className={classes.actions_container}>
          <div className={[classes.actions, classes.actions_add].join(' ')}>
            <button type="submit" disabled={isDisabled || disabledPrimaryInsurance() || disabledSecondaryInsurance()}>
              {type === patientFormType.create ? t('AddPatient') : t('UpdatePatient')}
            </button>
          </div>
          {loadingAddPatient && (
            <div className={[classes.actions]}>
            <CircularProgress/>
          </div>
          )}
          <div className={[classes.actions, classes.actions_clear].join(' ')}>
            <button onClick={handleFormReset}>{t('Clear')}</button>
          </div>
        </div>
        {userResponse && !userError && goSomeWhere(userResponse)}
      </form>
      {type === patientFormType.update && loadingPatientInformation &&
        <div className={classes.loading}>
          <CircularProgress />
        </div>}
    </div>
  );
}

export default NewUserForm;