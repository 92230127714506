import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChartsStyle from './Charts.Module.js'
import { useTranslation } from 'react-i18next'

const chartSetting = {
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)',
    },
  },
};

const valueFormatter = (value) => `${value}`;

export default function BarsDataset({dataset, title, notFoundDataMessage, loadingData}) {
  const classes = ChartsStyle()
  const labelColors = ['#3498db', '#4CAF50', '#F44336'];
  const { t } = useTranslation('analytics')

  if (Array.isArray(dataset) && dataset.length === 0) {
    return <Box sx={classes.notFoundMessageContainer}>
      <Typography sx={classes.notFoundMessageTitle}>{notFoundDataMessage}</Typography>
    </Box>
  }

  return (
    <>
      <Typography sx={classes.barTitle}>{title}</Typography>
      {dataset && <BarChart
      slotProps={{
        legend: {
          direction: 'row',
          position: { vertical: 'bottom', horizontal: 'middle' },
          padding: 0,
        },
      }}
        dataset={dataset}
        xAxis={[{ scaleType: 'band', dataKey: 'provider' }]}
        series={[
            { dataKey: 'Total', label: t('Total'), valueFormatter, color: labelColors[0] },
            { dataKey: 'Accepted', label: t('Approved'), valueFormatter, color: labelColors[1] },
            { dataKey: 'Rejected', label: t('Rejected'), valueFormatter, color: labelColors[2] },
          ]}
          seriesColors={labelColors}

        {...chartSetting}
        height={300}
      />}
    </>
  );
}