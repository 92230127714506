import PieChartGoogle from "./PieChartGoogle";
import { useContext, useState, useEffect } from 'react';
import AppContext from "../../contexts/app-context";
import AuthContext from "../../contexts/auth-context";
import useAxios from "../../hooks/useAxios";
import { urls, toastify_status } from '../config/variables';
import { validateMostUsedDrugs } from "../../Utils/validateHttpResponse";
import Toastify from "../ui/Toastify";
import { useTranslation } from 'react-i18next'

const MostUsedDrugsPieChart = () => {

    const [mostUsedDrugs, setMostUsedDrugs] = useState('')
    const appCtx = useContext(AppContext)
    const searchByDateQuery = appCtx.searchByDateQuery
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const { t } = useTranslation('analytics')

    useEffect(() => {
        updateDrugsCountChartData()
        // eslint-disable-next-line
    }, [searchByDateQuery])

    const updateDrugsCountChartData = () => {
        operation(
            {
                method: 'GET',
                url: urls.DRUGS_COUNT + searchByDateQuery,
                headers: { Authorization: `token ${token}` },
                data: {},
            },
            handleMostUsedDrugs,
            (err) => console.log(err)
        );
    }

    const handleMostUsedDrugs = (data) => {
        if (validateMostUsedDrugs(data)) {
            //Note new data this is the format that material ui is accepted [{"value":2,"label":"Hepatect CP"},{"value":2,"label":"Hepsera"}]
            const newData = data.most_used_drugs.map((item, i) => ({
                value: Number(item.drug_count),
                label: item.drug_name
            }))

            const transformedData = [
                ["Label", "Value"],
                ...newData.map(item => [item.label, item.value])
              ];
            //Note transformed data this is the format that google pie chart is accepted ["Label","Value"],["Hepatect CP",2],["Hepsera",2]
            setMostUsedDrugs(transformedData);
        } else {
            Toastify({message: 'invalid Most Used Drugs response data', state: toastify_status.ERROR})
            console.log('invalid Most Used Drugs response data')
        }
    }
    return <PieChartGoogle data={mostUsedDrugs} title={t('MostUsedDrugs')} notFoundDataMessage={t('NoData')}/>
}

export default MostUsedDrugsPieChart
