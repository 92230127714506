import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import Layout from './components/layout/Layout';
import AuthContext from './contexts/auth-context';
import AppContext from './contexts/app-context';
import AuthPage from './pages/AuthPage/AuthPage';
import RequireAuth from './components/Auth/RequireAuth';
import NotFound from './pages/NotFound/NotFound';
import NewEncounterPage from './pages/NewEncounterPage/NewEncounterPage';
import NavigationTab from './components/NavigationTab/NavigationTab';
import NewPatient from './pages/NewPatient';
import UpdatePatient from './pages/UpdatePatient';
// import MachineLearningPage from './pages/MachineLearningPage';
import DoctorEncounters from './pages/UserProfilePages/DoctorEncounters/DoctorEncounters';
import PatientProfilePage from './pages/UserProfilePages/PatientProfilePage';
import PatientPortalPage from './pages/UserProfilePages/PatientPortal/PatientPortalPage';
import DoctorProfilePage from './pages/UserProfilePages/DoctorProfilePage';
import InsuranceProfilePage from './pages/UserProfilePages/InsuranceProfilePage';
import FacilityProfilePage from './pages/UserProfilePages/FacilityProfilePage';
import PharmacyProfilePage from './pages/UserProfilePages/PharmacyProfilePage';
import InsuranceAnalysisPage from './pages/InsuranceAnalysisPage/InsuranceAnalysisPage';
import i18n from './locales/i18n';
import { app_paths } from './components/config/variables'
import CircularProgressComponent from './components/ui/CircularProgressComponent';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Setting from './pages/SettingsPages/Setting';

function App() {
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const userRole = appCtx.userRole;
  const language = appCtx.language

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const getRouteForDoctorRole = () => {
    return (
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<NavigationTab />}>
          <Route path="/" element={<MainPage />} />
          <Route path={app_paths.NEW_PATIENT} element={<NewPatient />} />
          <Route path={app_paths.MY_ENCOUNTERS} element={<DoctorEncounters />} />
          <Route path={app_paths.UPDATE_PATIENT} element={<UpdatePatient />} />
          <Route path={app_paths.NEW_ENCOUNTER} element={<NewEncounterPage />} />
        </Route>
        <Route path={app_paths.PATIENT_PROFILE} element={<PatientProfilePage />} />
        <Route path={app_paths.DOCTOR_PROFILE} element={<DoctorProfilePage />} />
        <Route path={app_paths.SETTINGS} element={<Setting />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };

  // const getRouteForAdminRole = () => {
  //   return (
  //     <Route path="/" element={<Layout />}>
  //       <Route path="/" element={<AdminProfilePage />} />
  //       <Route path="/patient/:patientID" element={<PatientProfilePage />} />
  //     </Route>
  //   );
  // };

  const getRouteForInsuranceRole = () => {
    return (
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<InsuranceProfilePage />} />
        <Route path={app_paths.NEW_PATIENT} element={<NewPatient />} />
        <Route path={app_paths.UPDATE_PATIENT} element={<UpdatePatient />} />
        <Route path={app_paths.PATIENT_PROFILE} element={<PatientProfilePage />} />
        <Route path={app_paths.INSURANCE_ANALYSIS} element={<InsuranceAnalysisPage />} />
        <Route path={app_paths.SETTINGS} element={<Setting />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };

  const getRouteForFacilityRole = () => {
    return (
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<FacilityProfilePage />} />
        <Route path={app_paths.PATIENT_PROFILE} element={<PatientProfilePage />} />
        <Route path={app_paths.SETTINGS} element={<Setting />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };

  const getRouteForPharmacyRole = () => {
    return (
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<PharmacyProfilePage />} />
        <Route path={app_paths.NEW_ENCOUNTER} element={<NewEncounterPage />} />
        <Route path={app_paths.PATIENT_PROFILE} element={<PatientProfilePage />} />
        <Route path={app_paths.SETTINGS} element={<Setting />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };


  const getRouteForPatient = () => {
    return (
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<PatientPortalPage />} />
        <Route path={app_paths.SETTINGS} element={<Setting />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };

  return (
    <>
      <Routes>
        <Route
          path={app_paths.LOGIN_AUTH}
          element={isLoggedIn ? <Navigate to="/" /> : <AuthPage />}
        />
        <Route element={<RequireAuth />}>
          {/* {userRole === "administrator" ? getRouteForAdminRole() : null} */}
          {userRole === "doctor" ? getRouteForDoctorRole() : null}
          {userRole === "insurance_admin" ? getRouteForInsuranceRole() : null}
          {userRole === "facility_admin" ? getRouteForFacilityRole() : null}
          {userRole === "patient" ? getRouteForPatient() : null}
          {userRole === "pharmacy_admin" ? getRouteForPharmacyRole() : null}
          {/* it take time to get userRole from the API so show circular progress in this time */}
          <Route path="*" element={<CircularProgressComponent />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
