import { useTheme } from '@emotion/react';

const UserProfilePageStyle = () => {
  const theme = useTheme();

  const patient_profile_button_style = ({isActive}) => {
    return {
      width: '150px',
      fontSize: '14px',
      border: '1px solid #C4C4C4',
      backgroundColor: isActive && '#4ABBA7',
      color: isActive && '#fff',
      '&: hover': {
      backgroundColor: '#4ABBA7',
      color: '#fff'
  }
    }
  }

  return {
    arrow_button: {
      marginTop: '-32px',
      marginLeft: '-12px',
    },
    arrow_icon: {
      fontSize: '36px',
      color: theme.palette.primary.main,
    },
    container: {
      padding: '40px',
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      marginBottom: '32px',
    },
    user_info_container: {
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' },
      alignItems: 'center',
      justifyContent: 'start',
      gap: { xs: '32px', sm: '10px', lg: '20px' },
    },
    avatar_container: {
      flexBasis: '20%',
      textAlign: 'center',
    },
    user_name: {
      fontWeight: '500',
      fontSize: '24px',
      margin: '32px 0 12px',
      textTransform: 'capitalize',
    },
    user_avatar: {
      width: '190px',
      height: '190px',
      margin: 'auto',
    },
    user_details: {
      flexBasis: '80%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '16px',
      rowGap: '8px',
    },
    user_info_box_extended: {
      padding: { xs: '4px 8px', sm: '12px 16px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      flexBasis: { xs: '100%', sm: '98%' },
      display: 'flex'
    },
    user_info_box: {
      padding: { xs: '4px 8px', sm: '12px 16px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      flexBasis: { xs: '100%', sm: '32%' },
      display: 'flex',

    },
    user_email_box: {
      padding: { xs: '4px 8px', sm: '8px 16px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      flexBasis: { xs: '100%', sm: '65.5%' },
      display: 'flex',
      alignItems: 'center',
    },
    user_address_box: {
      padding: { xs: '4px 8px', sm: '12px 16px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      flexBasis: { xs: '100%', sm: '99%' },
      display: 'flex'
    },
    user_insurance_container: {
      width: '99%',
      display: 'flex',
      alignItems: { xs: '', sm: 'center' },
      justifyContent: 'space-between',
      flexDirection: { xs: 'column', sm: 'row' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      padding: { xs: '4px 8px', sm: '12px 16px' },
    },
    user_insurance_title: {
      width: '148px',
    },
    user_insurance_box: {
      padding: { xs: '4px 8px', sm: '8px 12px' },
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      flexBasis: { xs: '100%', sm: '27.3%' },
      display: 'flex',
      margin: {xs: '4px 0', sm: '0'}
    },
    user_info_value: {
      color: theme.palette.custom.black['70'],
      marginLeft: '8px',
      marginRight: '8px',
      direction : 'ltr'
    },
    user_encounter_container: {
      marginTop: '32px',
      border: '1px solid #d9d9d9',
      borderRadius: '10px',
      padding: '24px 32px',
    },
    sub_title: {
      fontWeight: '500',
      fontSize: '20px',
    },
    search_container: {
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      gap: '8px',
      margin: '24px 0',
    },
    search_by: {
      flexBasis: '30%',
    },
    search_filed: {
      flexBasis: '50%',
    },
    search_button: {
      flexBasis: '20%',
      padding: '6px',
      maxHeight: '40px',
    },
    not_found_message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#686868',
      marginTop: '32px',
      gap: '8px',
    },
    analysis_page_button: {
      width: '150px',
      mt: '24px',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
    },
    alertBox: {
      marginTop: '10px'
    },
    alertTitle: {
      marginRight: '10px'
    },
    loadEncounters: {
      display: 'flex', justifyContent: 'center'
    },
    patient_profile_buttons_box: {
      display: 'flex',
      marginTop: '30px',
    },
    patient_profile_button: patient_profile_button_style,
    new_encounter_link: {
      textDecoration: 'none',
    },
    searchByStateBox: {
      display: 'flex',
      flex: 1
    },
    searchByStateButtons: {
       margin: '0 10px'
    },
    doctor_encounters_charts_box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      height: '300px'
    },
    doctor_encounters_charts_title: {
      textAlign: 'center',
      fontSize: '22px',
      letterSpacing: '2'
    },
    doctor_encounters_bar_chart: {
      width: '400px',
      height: '300px'
    },
    doctor_encounters_pie_chart: {
      transform: 'translateY(50px)'
    },
    medical_table_box: {
      maxHeight: '300px',
      overflowY: 'auto'
    },
    medical_table_close_button: {
      width: '100px',
      margin: 'auto'
    }
  };
};

export default UserProfilePageStyle;
