import { useContext, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AuthFormStyle from './AuthForm.module';
import ChangePasswordFormSchema from '../schema/ChangePasswordForm.shema';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import useAxios from '../../hooks/useAxios';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Toastify from '../ui/Toastify';
import { urls, http_response_messages, toastify_status, app_paths } from '../config/variables';
import CircularProgress from '@mui/material/CircularProgress';
import { validateUpdatedUserPassword } from '../../Utils/validateHttpResponse';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const ChangePasswordForma = () => {
  const classes = AuthFormStyle();
  const oldPasswordInputRef = useRef();
  const passwordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const [validationError, setValidationError] = useState(undefined);
  const authCtx = useContext(AuthContext)
  const appCtx = useContext(AppContext)
  const token = authCtx.token;
  const { operation } = useAxios();
  const [loadingSaveNewPassword, setLoadingSaveNewPassword] = useState(false)
  const navigate = useNavigate();
  const userRole = appCtx.userRole;
  const { t } = useTranslation('auth')

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredOldPassword = oldPasswordInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    const enteredConfirmPassword = confirmPasswordInputRef.current.value;
    const loginForm = { oldPassword: enteredOldPassword, password: enteredPassword, confirmPassword: enteredConfirmPassword };

    try {
      const { error } = ChangePasswordFormSchema.validate(loginForm, {
        abortEarly: false,
      });
      if (!error) {
        setLoadingSaveNewPassword(true)
        setValidationError(undefined);
        operation(
          {
            method: 'PUT',
            url: urls.CHANGE_PASSWORD,
            headers: { Authorization: `Token ${token}`},
            data: {
              old_password: enteredOldPassword,
              new_password: enteredPassword,
              new_password_confirm: enteredConfirmPassword
            },
          },
          handleSuccessChangePassword,
          handleErrorChangePassword,
        );
      } else {
        const newErrors = {};
        error.details.forEach((detail) => {
          const path = detail.path[0];
          const message = detail.message;
          newErrors[path] = message;
        });
        setValidationError(newErrors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSuccessChangePassword = (user) => {
    setLoadingSaveNewPassword(false)
    if(validateUpdatedUserPassword(user)) {
      Toastify({message: http_response_messages.CHANGE_PASSWORD, state: toastify_status.SUCCESS})
      goSomeWhere()
    } else {
      console.log('invalid updated password response')
      Toastify({message: 'invalid updated password response', state: toastify_status.ERROR})
    }
  }

  const handleErrorChangePassword = (error) => {
    setLoadingSaveNewPassword(false)
    if (error.response.data) {
      const errors = error.response.data.errors
      errors.map((error) => (
        Toastify({ message: error.detail, state: toastify_status.ERROR })
      ))
    } else {
      Toastify({ message: http_response_messages.ERROR, state: toastify_status.ERROR })
    }
  }

  const goSomeWhere = () => {
    if(userRole === 'doctor') {
      navigate(app_paths.DOCTOR_PROFILE.replace(':doctorName', authCtx.userProfile.user.name))
    } else {
      navigate('/')
    }
  }

  const PasswordField = ({ label, inputRef, error, helperText }) => {
    const [visibility, setVisibility] = useState(false)

    return <TextField
      variant="standard"
      type={visibility ? 'text' : 'password'}
      label={label}
      inputRef={inputRef}
      error={error}
      helperText={helperText}
      sx={classes.input_style}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setVisibility(prev => !prev)}>
              {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  }

  return (
    <Box component="form" onSubmit={submitHandler}>
      <Typography sx={classes.title}>{t('ChangePassword')}</Typography>

      <PasswordField
        label={t('OldPassword')}
        inputRef={oldPasswordInputRef}
        error={validationError?.oldPassword ? true : false}
        helperText={validationError?.oldPassword}
      />
      <PasswordField
        label={t('NewPassword')}
        inputRef={passwordInputRef}
        error={validationError?.password ? true : false}
        helperText={validationError?.password}
      />
      <PasswordField
        label={t('ConfirmNewPassword')}
        inputRef={confirmPasswordInputRef}
        error={validationError?.confirmPassword ? true : false}
        helperText={validationError?.confirmPassword}
      />
      <Button variant="contained" sx={classes.submit_button} type="submit">
      {t('Save')} {loadingSaveNewPassword && <CircularProgress size={20} sx={{color: 'white', marginLeft: '10px'}}/>}
      </Button>
    </Box>
  )
}

export  default ChangePasswordForma