import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import useAxios from '../../hooks/useAxios';
import AuthContext from '../../contexts/auth-context';
import { urls, toastify_status, pricesFileIndicators } from '../config/variables';
import { useDropzone } from 'react-dropzone';
import classes from './UploadFileForm2.module.css';
import Toastify from '../ui/Toastify';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useTranslation } from 'react-i18next'

const UploadFileForm2 = ({ facilities }) => {
  const [selectedFacility, setSelectedFacility] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [fileIndicator, setFileIndicator] = useState('');
  const [activeStep, setActiveStep] = useState('');
  const { operation } = useAxios();
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation('setting')
  const indicators = pricesFileIndicators()
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      if (acceptedFiles[0].name.split('.').pop() === 'csv') {
        setSelectedFile(acceptedFiles[0]);
        setActiveStep(2)
        setSelectedFacility('')

      } else {
        Toastify({
          message: 'Select CSV File',
          state: toastify_status.WARNING
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleChangeFacility = (e) => {
    const { value } = e.target;
    setSelectedFacility(value);
    setActiveStep(3)
  };

  const handleChangeFileIndicator = (e) => {
    setFileIndicator(e.target.value);
    setActiveStep(1)
    setSelectedFile('')
    setSelectedFacility('')
  };

  const handleUploadFile = () => {
    const formData = new FormData();
    formData.append(fileIndicator, selectedFile);
    formData.append('facility', selectedFacility);
    const url = fileIndicator === 'icd_file'
    ? urls.UPLOAD_ICD_PRICES
    : fileIndicator === 'cpt_file'
    ? urls.UPLOAD_CPT_PRICES
    : fileIndicator === 'drug_file'
    ? urls.UPLOAD_DRUG_PRICES
    : '';

    operation(
      {
        method: 'POST',
        url,
        headers: { Authorization: `token ${authCtx.token}` },
        data: formData,
      },
      handleSuccessFullyUploadedFile,
      handleErrorUploadedFile
    );
  };

  const handleSuccessFullyUploadedFile = (response) => {
    Toastify({
      message: response,
      state: toastify_status.SUCCESS
    });
    setActiveStep('')
    setSelectedFacility('');
    setSelectedFile('');
    setFileIndicator('');
  };

  const handleErrorUploadedFile = (error) => {
    Toastify({
      message: error?.response?.data,
      state: toastify_status.ERROR
    });
  };

  const steps = [
    t('Prices.SelectFileIndicator'),
    t('Prices.BrowseFiles'),
    t('Prices.SelectFacility'),
    t('Prices.uploadPrices'),
  ];

  return (<Box className={classes.upload_files_box}>
      <h1 className={classes.title}>{t('Prices.Title')}</h1>
      <br />
      <br />
  
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleChangeFileIndicator}
          value={fileIndicator}
        >
          {indicators.map((indicator, index) => {
            return <FormControlLabel key={index} value={indicator.value} control={<Radio />} label={indicator.label} />
          })}
        </RadioGroup>
      </FormControl>
  
      <Box className={classes.container}>
        <Box className={classes.row}>
          <Box
            className={classes.drag}
            {...getRootProps()}
            sx={{
              borderColor: isDragActive && '#4ABBA7',
              boxShadow: isDragActive && '0 0 10px #4ABBA7',
            }}
          >
            <input {...getInputProps()} accept=".csv" />
            <Box className={classes.dropzone}>
              {isDragActive ? (
                <Typography className={classes.title}>{t('Prices.DropHere')}</Typography>
              ) : (
                <Box>
                  <h1 className={classes.selected_file}>{selectedFile.name}</h1>
                  <CloudUploadIcon
                    sx={{
                      fontSize: '60px',
                      transition: '0.5s',
                      color: selectedFile ? '#FFAD60' : 'rgb(168, 168, 168)',
                    }}
                  />
                  <Typography className={classes.drag_zone_title}>
                    {t('Prices.DragAndDrop')}
                  </Typography>
                  <Typography className={classes.drag_zone_title}>-{t('Prices.Or')}-</Typography>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    {t('Prices.BrowseFiles')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
  
        <Box className={classes.row}>
          <Box className={classes.upload}>
            <InputLabel sx={{ color: selectedFile && '#4ABBA7' }} id="select-facility">
              {t('Prices.SelectFacility')}
            </InputLabel>
            <Select
              labelId="select-facility"
              className={classes.facility_info_select}
              value={selectedFacility}
              disabled={!selectedFile}
              onChange={handleChangeFacility}
            >
              {typeof facilities !== 'undefined' && facilities.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
  
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              onClick={handleUploadFile}
              disabled={!selectedFacility}
            >
              {t('Prices.uploadPrices')}
            </Button>
          </Box>
        </Box>
      </Box>
  
      <Box className={classes.stepper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  )
};
export default UploadFileForm2;
