import PieChartGoogle from "./PieChartGoogle";
import { useContext, useState, useEffect } from 'react';
import AuthContext from "../../contexts/auth-context";
import AppContext from "../../contexts/app-context";
import useAxios from "../../hooks/useAxios";
import { validateStateChart } from "../../Utils/validateHttpResponse";
import { urls, toastify_status } from '../config/variables';
import Toastify from "../ui/Toastify";
import { useTranslation } from 'react-i18next'

const StatePieChart = ({hideTitle}) => {
    const [analysisStateData, setAnalysisStateData] = useState('');
    const appCtx = useContext(AppContext)
    const searchByDateQuery = appCtx.searchByDateQuery
    const reFetchChartsData = appCtx.reFetchChartsData
    const { operation } = useAxios();
    const authCtx = useContext(AuthContext);
    const token = authCtx.token;
    const allStates = ["Partially accepted", "Rejected", "Approved", "Pending approval", "Canceled"];
    const { t } = useTranslation('analytics')

    const slices = {
        0: { color: "#FFC107" }, // Partially accepted
        1: { color: "#F44336" }, // Rejected
        2: { color: "#4CAF50" }, // Approved
        3: { color: "#747264" }, // Pending approval
        4: { color: "#0C0C0C" }, // Canceled
      }

    useEffect(() => {
      getAnalysisStateData()
      // eslint-disable-next-line
  }, [searchByDateQuery, reFetchChartsData])

  const getAnalysisStateData = () => {
      operation(
          {
            method: 'GET',
            url: urls.ANALYSIS_ENCOUNTERS + searchByDateQuery,
            headers: { Authorization: `token ${token}` },
            data: {},
          },
          handleGetAnalysisStateData,
          (err) => console.log(err)
      );
  }

  const handleGetAnalysisStateData = (response) => {
    if(validateStateChart(response)) {
      setAnalysisStateData(response)
      // state map takes the data from the backend [{"state":"Approved","state_count":1},{"state":"Partially accepted","state_count":7}]
      // then turned it into an object {"Approved":1,"Partially accepted":7}
      const stateMap = response.reduce((acc, item) => {
        acc[item.state] = item.state_count;
        return acc;
      }, {});

      const transformedData = [
        ["State", "State Count"],
        ...allStates.map(state => [t(state), stateMap[state] || 0])
      ];

      setAnalysisStateData(transformedData)
    } else {
      Toastify({message: 'invalid reloaded encounter response', state: toastify_status.ERROR})
    }
  }

    return <PieChartGoogle data={analysisStateData} title={t('EncounterState')} hideTitle={hideTitle} slices={slices} notFoundDataMessage={t('NoData')}/>
}

export default StatePieChart