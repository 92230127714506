import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfilePageStyle from './UserProfilePage.module';
import AuthContext from '../../contexts/auth-context';
import AppContext from '../../contexts/app-context';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useTranslation} from 'react-i18next'

const DoctorProfilePage = () => {
  const classes = UserProfilePageStyle();

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const appCtx = useContext(AppContext)
  const doctor = authCtx.userProfile.user;
  const direction = appCtx.direction

  const {t} = useTranslation('doctorProfilePage')

  return authCtx.error ? (
    <Box dir={direction}>
      <Alert severity="error">
        <AlertTitle sx={classes.alertTitle}>{t('NoDoctorInfoTitle')}</AlertTitle>
         {t('NoDoctorInfoMessage')}
      </Alert>
    </Box>
  ) : (
    <Box>
      <IconButton size="small" sx={classes.arrow_button} onClick={() => navigate(-1)}>
        <ArrowBackIcon fontSize="small" sx={classes.arrow_icon} />
      </IconButton>
      <Box sx={classes.container}>
        <Box sx={classes.user_info_container} dir={direction}>
          <Box sx={classes.avatar_container}>
            <Avatar
              alt="user1"
              src={doctor?.gender === 'F' ? '/images/doctor_woman.svg' : '/images/doctor_man.svg'}
              sx={classes.user_avatar}
            />
            <Typography sx={classes.user_name}>{doctor?.name}</Typography>
          </Box>
          <Box sx={classes.user_details}>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('GovernmentID')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.eid}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('Nationality')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.nationality}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
                {t('DateOfBirth')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.birthdate}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Age')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.age} Y
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Gender')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.gender === 'F' ? 'Female' : 'Male'}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('Employer')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.employer}
                </Typography>
            </Box>
            {/* <Box sx={classes.user_info_box}>
              <Typography>
                Insurance ID
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.insurance_id}
                </Typography>
              </Typography>
            </Box> */}
            <Box sx={classes.user_info_box}>
              <Typography>
               {t('MobileNumber')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.phone_number}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box_extended}>
              <Typography>
               {t('EmailAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.email}
                </Typography>
            </Box>
            <Box sx={classes.user_info_box_extended}>
              <Typography>
               {t('PhysicalAddress')}
              </Typography>
                <Typography component="span" sx={classes.user_info_value}>
                  {doctor?.physical_address}
                </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DoctorProfilePage;
